<template>
  <div>
    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          v-if="isShowDismiss"
          @click="dismiss"
        >解除资质
        </a-button>
      </a-space>
    </div>

    <a-row>
      <a-col :span="8">
        <a-descriptions title="工商信息" layout="vertical" :column="1">
          <a-descriptions-item label="企业全称">
            {{ data.name }}
          </a-descriptions-item>
          <a-descriptions-item label="工商营业执照注册号">
            {{ data.registration_no }}
          </a-descriptions-item>
          <a-descriptions-item label="企业工商营业执照">
            <img
              v-if="data.business_license_url"
              class="img"
              :src="data.business_license_url"
              alt=""
              @click="handlePreview(data.business_license_url)"
            >
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions title="法人信息" layout="vertical" :column="1">
          <a-descriptions-item label="法人姓名">
            {{ data.legal_name }}
          </a-descriptions-item>
          <a-descriptions-item label="法人身份证号">
            {{ data.identity_card }}
          </a-descriptions-item>
          <a-descriptions-item label="法人身份证照片（正面）">
            <img
              v-if="data.front_identity_card_url"
              class="img"
              :src="data.front_identity_card_url"
              alt=""
              @click="handlePreview(data.front_identity_card_url)"
            >
          </a-descriptions-item>
          <a-descriptions-item label="法人身份证照片（反面）">
            <img
              v-if="data.back_identity_card_url"
              class="img"
              :src="data.back_identity_card_url"
              alt=""
              @click="handlePreview(data.back_identity_card_url)"
            >
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions title="设备信息" layout="vertical" :column="1">
          <a-descriptions-item label="终端设备名称">
            {{ data.device_name }}
          </a-descriptions-item>
          <a-descriptions-item label="终端设备照片">
            <img
              class="img"
              v-for="(device_url,i) in data.devices_urls"
              :key="i"
              :src="device_url"
              alt=""
              @click="handlePreview(device_url)"
            >
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>

    <preview-image
      :visible.sync="previewVisible"
      :image.sync="previewImage"
    />
  </div>
</template>

<script>
import { dismissEnterpriseQualification } from '@/api/enterprise_qualification'

import PreviewImage from '@/components/PreviewImage'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'EnterpriseQualificationInfo',
  components: { PreviewImage },
  data() {
    return {
      previewVisible: false,
      previewImage: ''
    }
  },
  computed: {
    isShowDismiss() {
      return hasPermission('enterprise_qualifications/dismiss')
    }
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    // 解除企业资质
    dismiss() {
      const vm = this
      const id = this.data.id
      this.$confirm({
        title: '确定解除资质认证吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          dismissEnterpriseQualification(id).then((res) => {
            if (res.code === 0) {
              // 告知父组件已完成
              vm.$emit('completed')
            }
          })
        }
      })
    },

    // 显示图片预览
    handlePreview(imageURL) {
      this.previewImage = imageURL
      this.previewVisible = true
    }
  }
}
</script>

<style scoped lang="less">
.img {
  float: left;
  margin: 10px;
  width: 100px;
  height: 100px;
  max-width: 100%;
  min-width: 100px;
  object-fit: cover;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.img:hover {
  cursor: pointer;
}
</style>
